@import "../../../scss/variables";

.headerStripe {
  background-color: $light-grey;
  height: 2.5rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: solid 1px $weak-grey;
  display: flex;
  align-items: center;

  .text {
    cursor: pointer;
    color: $idle-gray;
    font-size: 0.75rem;
  }

  &Icon {
    font-size: 0.625rem;
  }
}

.pillContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  margin-left: 0.375rem;
  padding: 0.25rem 0.625rem;
  border-radius: 1.25rem;

  &Primary {
    @extend .pillContainer;
    background-color: $soft-green;
    border: solid 0.5px $good-green;
    color: $good-green;
  }

  &Warning {
    @extend .pillContainer;
    background-color: $alternate-yellow-c;
    border: solid 0.5px $warning-yellow;
    color: $warning-yellow;
  }

  &Muted {
    @extend .pillContainer;
    background-color: $faded-grey;
    border: solid 0.5px $message-gray;
    color: $message-gray;
  }
}

.pillText {
  margin: 0 0 0 0.25rem;
  color: $standard-grey;
  font-size: 0.75rem;
}

.pillIcon {
  font-weight: 900;
  font-size: 0.75rem;
}

.tabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $faded-grey;
  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;

  &Clickable:hover {
    cursor: pointer;
    background-color: $light-grey;
  }
}

.addMissingDataButton {
  height: 2rem;
  font-weight: 400;
  font-size: 0.75rem;
}

.tabText {
  margin: 0;
  color: $idle-gray;
  font-size: 0.75rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 400;
  color: $standard-grey;
  margin-bottom: 0.5rem;
  line-height: 2rem;
}

.subTitle {
  font-size: 0.75rem;
  font-weight: 400;
  color: $black-grey;
  margin-bottom: 0;
}

.statBadge {
  color: $black-grey;
  line-height: 1.25rem;
  background-color: $light-grey;
  border-radius: 8px;
  box-shadow: 0.5px 0.5px 2px 0 #00000040 inset;
  transition: background-color 0.15s linear;
  padding: 0.375rem 0.75rem;
  margin-left: 1rem;

  &.isClickable {
    cursor: pointer;
    &:hover {
      background-color: darken($light-grey, 3);
    }
  }
}
