@import "../../../../../../../scss/variables";

.pathSelectorsHeader {
  padding-left: 40px;

  strong {
    line-height: 24px;
    vertical-align: middle;
  }
}

.pathSelectorContainer {
  padding-left: 70px;
}

.defaultValueContainer {
  padding-left: 40px;

  strong {
    line-height: 24px;
    vertical-align: middle;
  }
}

