@import "../scss/variables";

.noSearchResults {
  display: flex;
  flex-direction: column;
  align-items: center;

  &Title {
    margin-block: 1rem 0;
    color: $message-gray;
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 400;
  }

  &Text {
    margin-block: 0.75rem 0;
    color: $message-gray;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  &Button {
    margin: 0;
    color: $standard-grey;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &ButtonIcon {
    color: $idle-gray;
  }
}
