@import "../../../../../scss/variables";

.infoRow {
  background-color: $standard-grey;
  color: white;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.625rem 3rem;
  margin: 0px -0.75rem 0px -0.75rem;
}
