@import "../../scss/variables";

.title {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2rem;
  color: $black-grey;
  margin: 0;
}

.primaryActionButton {
  padding-block: 0.5rem;
}
