@import "../../../../../scss/variables";

.container {
  padding: 1.25rem 3.25rem 1.5rem;

  .outputName {
    color: $standard-grey;
    font-size: 0.75rem;
    font-weight: 400;
  }

  .title {
    color: $standard-grey;
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

}

