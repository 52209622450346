@import '../../../../scss/variables';

.separator {
  color: $weak-grey;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.rule {
  position: relative;

  .removeIcon {
    cursor: pointer;
    background-color: $danger;
    color: $white;
    font-size: 10px;
    padding: 4px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    z-index: 9999;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .removeBlockIcon {
    font-size: 0.875rem;
    color: $black-grey;
  }
}

.removeRule {
  cursor: pointer;
  line-height: 40px;
  padding-left: 12px;
  font-weight: 900;
  color: $primary-gray;

  &:hover {
    color: black;
  }
}

.brandRuleRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $light-grey;
  border: 0.5px solid $disabled-gray;
  border-radius: 1.25rem;
  margin-top: 1.5rem;
  padding: 1.5rem;


  .brandRuleText {
    margin-right: 0.75rem;
    font-size: 0.875rem;
  }

  .brandDropdownContainer {
    margin-right: 1rem;

    .brandDropdownToggle {
      width: 100%;
    }
  }

  .addRuleButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }

}

.exceptionsContainer {
  display: flex;
  flex-direction: row;

  .ruleExceptionRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    .exceptionText {
      color: $primary-gray;
      padding-right: 1rem;

      img {
        padding-bottom: 3px;
      }
    }

    .exceptionField {
      padding-right: 12px;

    }

    .exceptionOp {
      padding-right: 16px;
    }

    .exceptionValue {
    }
  }
}


.brandRuleIcon {
  background-color: $primary-gray;
  color: white;

  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 1rem;
}
