@import 'src/ui-kit/src/scss/variables';

.content {
  overflow-y: auto;
  height: 30.875rem;
  color: $standard-grey;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $disabled-gray;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $message-gray;
    border-radius: 8px;


    &:hover {
      background: $idle-gray;
    }
  }
}

.lastRow {
  border-bottom: 0.5px solid $weak-grey;
}

.rowContainer {
  border-top: 0.5px solid $weak-grey;
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
}

.storeName {
  font-weight: 400;
  font-size: 0.875rem;
}

.storeAddress {
  font-weight: 400;
  font-size: 0.75rem;
}

.redIcon {
  color: $error-red;
  cursor: pointer;
}

.grayIcon {
  color: $weak-grey;
}

.modalHeader {
  padding: 2rem 3rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0;
  font-size: 1.75rem;
  align-items: center;
}

.closeIcon {
  font-size: 1.5rem;
  color: $idle-gray;
  font-weight: 300;
  cursor: pointer;
}

.title {
  font-weight: 400;
  font-size: 1.75rem;
}
