@import '../../../scss/variables';


.outputFieldConfigScreen {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;

  .roundedHeader {
    display: flex;
    justify-content: space-between;
    // text
    text-transform: uppercase;
    font-size: 12px;
    color: $primary-gray;
    font-weight: normal;
    // the box
    background-color: $accent-gray;
    padding: 7px 14px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 5px;
  }

  .listGroup {
    min-height: 25rem;
    max-height: 37.5rem;
    border-radius: 0;
    margin-right: -2rem;
    margin-left: -2rem;
    position: relative;

  }
}


.footer {
  padding: 24px 20px 12px;
  border-top: 1px solid $message-gray;

  :global .btn {
    margin-left: 16px;
  }
}

.generateWithAiButton {
  margin-left: 1rem;
  text-wrap: nowrap;
}
