@import '../../scss/variables';

$dashed-border-color: $standard-grey;
$dashed-border-hover-color: $alternative-purple-a;

.container {
  cursor: pointer;
  position: relative;
  height: 100%;
}


.border {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  min-height: 16rem;
  z-index: 100;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;

  &:hover {
    background-color: $faded-purple;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23BA8FFFFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &.forceHover {
    background-color: $faded-purple;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23BA8FFFFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}


.image {
  height: 5.5rem;
  margin-bottom: 1rem;
  margin-top: 4rem;
}

.title {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.subtitle {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: $idle-gray;
}

.hiddenInput {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 50;
}

.uploadedFileIndicator {
  display: block;
  position: relative;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 100;
}

.scrollableContainer {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $disabled-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $message-gray;
    border-radius: 8px;

    &:hover {
      background: $idle-gray;
    }
  }
}
