@import '../../../../../scss/variables';

.tokenBase {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.fromDisplayToken {
  background-color: $accent-purple-2;
  border: 0.5px solid #4F4F4F;
}

.field {
  @extend .tokenBase;
  color: $secondary-gray !important;

  &.withRules {
    color: $primary !important;
  }

  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
}
