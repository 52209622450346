@import "../../../scss/variables";


.popover {
  :global .popover.show {
    padding: 0;
    max-height: 25rem;
    overflow: auto;
    min-width: 20rem;

    :global .list-group-item {
      font-size: 14px;
      color: $black-grey;
      padding: 12px;
      border-bottom: 2px solid $disabled-gray;
    }
  }
}
