@import 'src/ui-kit/src/scss/variables';

.dropdownContainer {
  margin-right: 0.625rem;
}

.dropdownMenuButton {
  margin-block: 2px;

  &:hover i, &Active i {
    font-weight: 900 !important;
  }

  &Active {
    color: $standard-grey;
    background-color: $accent-purple;
    outline: none;
  }
}

.smallFont {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.userIcon {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem;

  &Active {
    font-weight: 900 !important;
  }
}

.chevronIcon {
  font-size: 0.5rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem;
}