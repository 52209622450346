@import "../../../scss/variables";

.productRow {
  display: flex;
  height: 5.125rem;
  border-bottom: 0.5px solid $disabled-gray;

  &MissingValues {
    background-color: $alternate-yellow-c;
  }
}

.productCell {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &StyleName {
    @extend .productCell;
    gap: 0.5rem;
  }
}

.productText {
  color: $standard-grey;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
