@import "../../../../../scss/variables";

.container {
  background-color: $accent-gray;
  padding-left: 4.375rem;
  padding-bottom: 1.5rem;
  margin-left: 0;
  margin-right: 0;

  font-size: 0.875rem;
  color: $standard-grey;
  font-weight: 400;
  line-height: 1.25rem;

  button {
    min-height: 2rem;
  }

  &.lastRow {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}


.alwaysAppliedBox {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0.75rem;
  width: 16.5rem;

  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: $standard-grey;

}
