@import "../../../../scss/variables";

.container {
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  overflow-y: auto;
  width: 64rem;
  height: 31rem;
}

.tableHeader {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.tableHeaderCell {
  font-weight: 400;
  font-size: 0.75rem;
  color: $standard-grey;
}

.tableRowContainer {
  border-bottom: 0.5px solid $weak-grey;
}

.tableRow {
  display: flex;
  align-items: center;
  padding-inline: 1.5rem;
}

.openedTableRow {
  background-color: $accent-gray;
}

.closedTablerow {
  background-color: $white;
}

.caretIcon {
  height: min-content;
  padding: 0.5rem 0.375rem;
  margin-inline: -0.375rem;
  margin-block: auto;
  cursor: pointer;
  font-size: 0.875rem;
  color: $standard-grey;
}

.styleNumberText {
  margin: 0;
  font-size: 0.875rem;
  color: $standard-grey;
}

.colorCircle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.colorSubtext {
  margin: 0 0 0 0.375rem;
  font-size: 0.75rem;
  color: $standard-grey;
}

.subvariantsContainer {
  border-top: 0.5px solid $weak-grey;
  margin-inline: 1.5rem;
}

.subvariantRowContainer {
  display: flex;
  padding-block: 1rem;
}

.subvariantNameContainer {
  width: 3.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 0.5px solid $message-gray;
  background-color: $weak-grey;
}

.subvariantNameAndGtinText {
  margin: 0;
  font-size: 0.75rem;
  color: $idle-gray;
}

.noDataIcon {
  font-size: 0.75rem;
  color: $warning-yellow;
}

.normalTableTextCell {
  margin: 0;
  font-size: 0.875rem;
  color: $idle-gray;
}

.smallTableTextCell {
  margin: 0;
  font-size: 0.563rem;
  color: $idle-gray;
}

.input {
  border-color: $weak-grey;
  color: $standard-grey;
}

.requiredInput {
  border-color: $alternate-yellow-a;
  background: $alternate-yellow-c;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.input[type=number] {
  -moz-appearance: textfield;
}
