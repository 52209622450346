@import '../../../scss/variables';

.grayBackground {
  background-color: $light-grey;

  &:hover {
    background-color: $weak-grey;
  }
}

.idleGrayText {
  color: $idle-gray
}

.listGroupItem {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid $message-gray;
  padding: 1rem 1.5rem 1rem;


  &:last-child {
    border-bottom: 0;
  }

  :global .index {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.375rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: $white;
    border-radius: 50%;
    border: 1px solid $black;
    text-align: center;
    vertical-align: center;
    background-color: $black;

    &.emptyRowIndex {
      color: $black;
      border-color: $black;
      background-color: $white;
    }
  }

  .outputFieldName {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .example-text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  :global .dropdown-toggle::after {
    // hide the caret. If we use the caret={false} props it messes up the toggle button for some reason
    display: none;
  }
}

.required {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $standard-grey;
  font-size: 0.75rem;

  &Icon {
    font-size: 0.625rem;
    font-weight: 300;
    cursor: pointer;
    transition: color ease-out 0.25s, font-weight ease-in-out 0.25s;

    &:hover {
      color: $purple;
    }

    &Enabled {
      color: $standard-grey;
      font-weight: 900;
    }
  }
}
