@import "../scss/variables";

.tabsContainer {
  background: $light-grey;
  box-shadow: inset 0.5px 0.5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  min-height: 2rem;
  font-size: 0.75rem;
}

.activeTab {
  background: $white;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  height: 2rem;
  cursor: default;
  user-select: none;
}

.inactiveTab {
  cursor: pointer;
  user-select: none;
}

.disabledTab {
  color: $message-gray;
  cursor: default;
}
