@import '../../../../scss/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $light-grey;
  border: 0.5px solid $disabled-gray;
  border-radius: 1.25rem;
  padding: 1.5rem;
}
