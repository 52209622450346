// Here you can add other styles

@import url('https://fonts.googleapis.com/css?family=Archivo&display=swap');

.tree, .tree ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.tree ul {
  margin-left: 1em;
  position: relative
}

.tree ul ul {
  margin-left: .5em
}

.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid
}

.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: $dark;
  font-weight: 700;
  position: relative
}

.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0
}

.tree ul li:last-child:before {
  background: #ffffff;
  height: auto;
  top: 1em;
  bottom: 0
}

.indicator {
  margin-right: 5px;
}

.tree li a {
  text-decoration: none;
  color: #369;
}

.tree-item-selected {
  display: block;
  background: $primary;
  color: $light;
}

.tree span {
  cursor: pointer;
}

.tree li button, .tree li button:active, .tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  outline: 0;
}

.product-template-list-item {
  padding: 10px;
  cursor: pointer;
  height: 48px;
}

.product-template-list-item-selected {
  padding: 10px;
  cursor: pointer;
  height: 48px;
  background: rgba(200, 206, 211, 0.34);
  border-color: #c8ced3;
}

.product-template-list-item:hover {
  background: rgba(200, 206, 211, 0.34);
  border-color: #c8ced3;
}

.app-header {
  background-color: #791AFF
}

.app .app-body .sidebar {
  flex-basis: $sidebar-width;
  background: white;
}

.sidebar-fixed .sidebar {
  width: $sidebar-width;
}

.sidebar .sidebar-nav {
  background: white;
  padding-inline: 12px;
}

.sidebar .nav {
  width: 100%;
}


.sidebar .nav-link {
  color: #808080;

}

.sidebar .nav-link.active {
  background: $primary-light;
  color: $primary;
  border-right: 3px solid $primary;
}

.sidebar .nav-link.active:hover {
  background: $primary;
  color: $white;
}

.sidebar .nav-link.active:hover .nav-icon {
  color: $white;
}

.navbar-nav .nav-link {
  color: white;
}

body {
  background: #F2F2F2;
}

.sidebar .nav-title {
  color: #791AFF;
}

.sidebar .sidebar-minimizer {
  background: white;
}

.sidebar-minimized .sidebar .sidebar-minimizer {
  background: white;
}

.sidebar-minimized .sidebar-nav {
  padding: 0;
}

.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.breadcrumb {
  border-bottom: none;
}

// FilePond Style <------ Start ------>

.filepond--list {
  display: none;
}

/* use a hand cursor instead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: #555;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

///* the background color of the filepond drop area */
//.filepond--panel-root {
//  background-color: #ffffff;
//}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.7em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.7em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: #555;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
  color: white;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

/* bordered drop area */
.filepond--panel-root {
  background: #ffffff url("../assets/img/upload_background.svg");
  border: 1.5px dashed #808080;
}

//
//.filepond--list.filepond--list {
//  position: absolute;
//  top: 90px;
//  margin: 0;
//  padding: 0;
//  list-style-type: none;
//  will-change: transform;
//}

.filepond--root {
  height: 25.5em;
  max-height: 25em;
}

.filepond-label-text {
  color: black;
  font-size: x-large;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
}

.filepond--label-action {
  color: $primary;
  text-decoration: none;
  font-size: large;
}

.filepond--label-action label {
  color: $primary;
  text-decoration: none;
  font-size: large;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.filepond--drop-label {
  margin-top: 165px;
}

// FilePond Style <------ End ------>

.file-upload-modal-icon {
  font-size: xx-large;
  color: #8235FF;
}

.file-upload-modal-progress-bar {
  margin-top: -16px;
}

.file-upload-modal-file-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
}


.filepond--label-action i {
  font-size: 50px;
}

.filepond--label-action label {
  font-size: medium;
}

.table th, .table td {
  border-top: none;
}

.table td, .table th {
  border-top: none;
}

.text-600 {
  font-weight: 600;
  font-size: 14px;
}


.fade-enter div {
  overflow-y: hidden;
  max-height: 0;
}

.fade-enter-active div {
  max-height: 50px;
  transition: all 500ms ease-in;
}

.fade-exit div {
  max-height: 50px;
}

.fade-exit-active div {
  max-height: 0;
  overflow-y: hidden;
  transition: all 500ms ease-in;
}

.card-fixed-height {
  height: 176px;
}

.media-room-img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 92px;
  height: 92px;
}

.timeline-item {
  border-radius: 10px;
  padding: 2px;
  margin: 0 auto;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0
  }
  100% {
    background-position: 400px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f2f2;
  background: linear-gradient(to right, #f2f2f2 8%, #dddddd 18%, #f2f2f2 33%);
  background-size: 800px 104px;
  height: 25px;
  position: relative;
  border-radius: 10px;
}

.animated-background-white {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 8%, #dddddd 18%, #ffffff 33%);
  background-size: 800px 104px;
  height: 25px;
  position: relative;
  border-radius: 10px;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}


.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.card-shadow {
  -webkit-box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
  box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
}

.card-fixed-height-search {
  height: 812px;
}

.card-footer {
  background: $white;
}

.empty-list {
  background: rgba(228, 229, 230, 0);
  border-radius: 0.7rem;
  height: 494px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-list div {
  background: rgba(228, 229, 230, 0.41);
  padding: 40px;
  border-radius: 25rem;
  position: absolute;
  margin-top: -20px;
}

.empty-list img {
  margin-left: 10px;
}

.empty-list p {
  position: absolute;
  margin-top: 135px;
}


.retailer-select, .fancy-select {

  vertical-align: center;

  .dropdown .dropdown-toggle {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 38.5px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0659179px;
    text-align: center;
    color: #808080;
  }
}

button {

  &.btn.btn-outline-primary {
    border: 2px solid #791AFF;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;

    &:hover {
      background-color: $primary-light;
      color: $primary;
    }
  }

  .btn.btn-primary {
    background-color: #791AFF;
  }
}


h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.131836px;

  color: #231F35;

}


.alert {
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;

  &.alert-secondary {
    background-color: #E3E3EB;
  }
}

.popover {
  border: 0 transparent;
  padding: 16px;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
}


.multi-file-management {

  background-color: transparent;
  border: 2px dashed #E2E2E2;
  border-radius: 4px;
  padding: 7px;
  min-height: 17rem;
  color: #231F35;

  .list-group-item {
    border: 0;
  }

  label {
    min-width: 100%;
  }

  .label-no-files {
    cursor: pointer;
    margin-top: 5rem;

    span:first-child {
      text-align: center;
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.0988769px;
    }

    span:last-child {
      i {
        color: $primary;
        font-size: 50px;
      }

      font-family: Archivo;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.0769042px;
    }

  }

  .label-some-files {
    span {
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0769042px;
      color: $primary;
    }
  }

  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

}

.blured-navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  filter: blur(5px);
}

header.app-header.navbar {
  background-color: var(--primary);
  border-bottom: 0;
}

@import "./toastify";
@import "components/empty";
@import "components/file_input";
@import "./collection-modal";

@import "./animation-fixes";