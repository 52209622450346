@import '../../scss/variables';

.roundedTableHeader {
  background-color: $accent-gray;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 30px;
  color: $primary-gray;
  font-size: 12px;
  line-height: 17px;
  font-weight: normal;
  text-transform: uppercase;

  td:first-child {
    padding-left: 12px;
  }

  td {
    padding: 0;
    vertical-align: middle;
  }
}

.roundedHeaderContainer {
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
