
.header {
  font-size: 14px;
  font-weight: 600;

  margin-top: -0.75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  align-items: center;
  height: 6rem;
  padding: 0;
}

.chevron {
  font-size: 1rem;
  font-weight: 900;
  margin-left: 2.5rem;
  margin-right: 0.75rem;
  line-height: 1.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 400;
}
