@import '../../../../../../scss/variables';

.selectableOption{
  font-size: 0.75rem;
  line-height: 1.0625rem;
}

.selectedOption {
  color: $alternative-purple-a;
}

.unselectedOption {
  color: $standard-grey;
}

.iconBackground {
  width: 1.5rem;
  height: 1.5rem;
  background-color: $black-grey;
}
