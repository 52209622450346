@import "../../../../../../../scss/variables";

.iconContainer {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: $standard-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;;
}

.icon {
  color: white;
  font-size: 0.625rem;
}

.lightText {
  color: $standard-grey;
}

.ruleInput {
  margin-left: 0.75rem;
}

.checkedRadioButton {
  font-size: 1rem !important;
  line-height: 1rem !important;
  color: $purple;
}

.uncheckedRadioButton {
  font-size: 1rem !important;
  line-height: 1rem !important;
  color: $message-gray;
}
