@import '../../../../../scss/variables';

.stringLiteral {
  text-align: center;
  border: 1px solid $idle-gray;
  border-radius: 2.5rem;
  height: 2rem;
  font-size: 0.75rem;
}

.errorBorder {
  border: 0.5px solid $error-red !important;
  border-radius: 8px;
}

.separatorToken {
  background: $white;
  box-shadow: none;
  border: solid 0.5px $weak-grey;
  border-radius: 0.5rem;
  width: 1.875rem;
  height: 2rem;
}

.dropdownExample {
  float: right;
  margin-right: 0.75rem;
  color: $idle-gray;
}

.checkBox {
  font-size: 0.875rem !important;
  translate: 0 0.0625rem;
}

.activeOption {
  color: $brand-purple;
}
