@import '../../scss/variables';

.columnsSelectorTag {
  cursor: inherit;
  font-weight: 400;
  font-size: 0.75rem;
  color: $standard-grey;
  white-space: nowrap;
  margin: 0 0 0 0.25rem;
}

.selectorOption {
  cursor: inherit;
  font-weight: 400;
  font-size: 0.75rem;
  color: $standard-grey;
  margin: 0;
}

.selectorOptionCheckbox {
  color: $message-gray;
  margin-right: 0.5rem !important;

  &Disabled {
    @extend .selectorOptionCheckbox;
    color: $weak-grey;
  }

  &Checked {
    color: $purple;
  }
}

.dropdown {
  scrollbar-width: thin;
}
