@import '../../scss/variables';


.textButtonUnderline {
  position: relative;

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
  }

  &.cancelTextButtonUnderline {
    &:after {
      background-color: $black-grey;
    }

    &:hover:after {
      background-color: $teal;
    }
  }

  &.continueTextButtonUnderline {
    &:after {
      background-color: $teal;
    }

    &:hover:after {
      background-color: $black-grey;
    }
  }
}

.continueTextButton:hover {
  color: $black-grey;
  text-decoration: none;
}

.stepChooseDdt {
  padding: 24px;
  height: 100%;

  .dottedContainer {
    background-image: repeating-linear-gradient(0deg, $secondary-gray, $secondary-gray 17px, transparent 17px, transparent 30px, $secondary-gray 30px),
    repeating-linear-gradient(90deg, $secondary-gray, $secondary-gray 17px, transparent 17px, transparent 30px, $secondary-gray 30px),
    repeating-linear-gradient(180deg, $secondary-gray, $secondary-gray 17px, transparent 17px, transparent 30px, $secondary-gray 30px),
    repeating-linear-gradient(270deg, $secondary-gray, $secondary-gray 17px, transparent 17px, transparent 30px, $secondary-gray 30px);
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    height: calc(430px - 85px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
    }
  }
}

.stepUploadFiles {
  min-height: 100%;

  .fileInput {
    position: relative;
    padding: 24px 24px 24px 24px;
    min-height: 370px;
    display: grid;
    width: 0;
  }

  .cancelLinkContainer {
    padding-top: 0;
    margin-top: -64px;
    position: relative;
  }
}

