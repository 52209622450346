@import 'src/ui-kit/src/scss/variables';

.standardInput {
  max-width: 12.75rem;
  border: 0.5px solid $idle-gray;

  &:focus {
    border: 1px solid $alternative-purple-a;
  }
}

.zipCodeInput {
  max-width: 5rem;
  border: 0.5px solid $idle-gray;

  &:focus {
    border: 1px solid $alternative-purple-a;
  }
}

.grayElement {
  color: $weak-grey
}
