@import '../../../../scss/variables';

.container {
  margin-block: 0.5rem;
  padding: 0.5rem 2.5rem;
}

.tooltipContainer {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 1.25rem 1rem;
}

.info {
  font-weight: 600;
  color: $standard-grey;
  margin: 0;
}

.tooltip {
  font-weight: 100;
  font-size: 1rem;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-top: 0.5px solid $weak-grey;
}

.dataContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.title {
  font-weight: 600;
  color: $standard-grey;
  margin: 0;
}

.subtitle {
  font-size: 0.875rem;
  color: $standard-grey;
  margin: 0;

  &Disabled {
    color: $weak-grey;
  }
}
