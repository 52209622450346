@import "../scss/variables";


.defaultToggleButton {
  font-size: 14px;
  max-width: 200px;
  height: 32px;
  background-color: $white;
  border-radius: 40px;
  border: 1px solid $secondary-gray;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  color: $secondary-gray;
  padding-top: 5px;
  margin-bottom: 5px;

  &:disabled {
    background-color: $disabled-gray;
    border-color: $disabled-gray;
    color: $white;
    box-shadow: none;

    .placeholder {
      color: $white;
    }

    &:hover {
      background-color: $disabled-gray;
      border-color: $disabled-gray;
      color: $white;
      box-shadow: none;
    }
  }

  &:hover {
    background-color: $secondary-gray;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    border: 1px solid $secondary-gray;
    color: $white;

    .placeholder {
      color: $white;
    }
  }

  &:focus, &:focus:hover, &:active {
    background-color: $primary-gray;
    box-shadow: 0 0 0;
    color: $white;

    .placeholder {
      font-weight: 400;
      color: $white;
    }
  }

  .placeholder {
    font-weight: 400;
    color: $secondary-gray;
  }

  &:after {
    line-height: 16px;
    vertical-align: middle;
    margin-left: 8px;
  }
}


.dropdownContainer {
  border: 0;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding-bottom: 12px;
  padding-top: 12px;

  // With multiple items, don't allow it to increase in height too much
  // and make it scrollable
  overflow-y: auto;
  max-height: 25rem;

  // Scrollbar styling
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $disabled-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $message-gray;
    border-radius: 8px;

    &:hover {
      background: $idle-gray;
    }
  }

  // The dropdown clickable item
  .dropdownItem {
    height: 28px;
    font-size: 14px;
    vertical-align: center;
    padding: 4px 16px 4px 16px;
    border: 0;
    border-radius: 0;

    i {
      width: 14px !important;
      color: $black;
    }

    &:hover {
      background-color: $accent-purple;
    }
  }

  // Dropdown header
  .dropdownHeader {
    background-color: $white;
    border: 0;
    color: $primary-gray;
    font-weight: 600;
    text-transform: uppercase;
    padding: 7px 16px 7px 16px;
    cursor: default;
  }

  // Searchable
  .dropdownSearch {
    padding: 16px;
    position: relative;

    // "manually" position the 14x14 svg icon
    img {
      position: absolute;
      height: 16px;
      width: 16px;
      left: 32px;
      top: 24px;
    }

    input {
      height: 32px;
      border: 1px solid #828282;
      border-radius: 8px;
      font-size: 14px;
      // do not overlap the writing on the icon
      padding-left: 38px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25)
    }
  }
}

.subMenuRight {
  left: 264px!important;
}

.subMenuLeft {
  left: -264px!important;
}
