@import '../../../scss/variables';

.text {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $black-grey;
}

.redirectCardText {
  margin-bottom: 0;
  margin-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: $black-grey;
}

.starIcon {
  color: $warning-yellow;
}

.arrowIcon {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: $black-grey;
}

.redirectCard {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  border: 0.5px solid $message-gray;
  cursor: pointer;
  background-color: white;

  &:hover {
    background: $light-grey;
    border: 0.5px solid $idle-gray;
    transition: all 0.25s ease-out;
  }
}

.lightDropdownText {
  margin-left: auto;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: $idle-gray;
}
