@import "../variables.scss";

.searchInput {
  width: 100%;
  position: relative;

  input {
    padding-left: 38px !important;
  }
}

.searchIcon {
  color: $idle-gray;
  font-size: 14px;
  position: absolute;
  left: 14px;
  top: 8px;
}
