@import "../../../scss/variables";

.container {
  min-width: 45.5rem;
  padding-block: 4.375rem 3rem;
}

.title {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  color: $standard-grey;
  text-align: center;
}

.subtext {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $idle-gray;
  text-align: center;
}
