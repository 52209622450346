@import "../scss/variables";

.modalIcon {
  color: white;
  width: 20px;
  height: 20px;
}

.innerIconBackground {
  width: 44px;
  height: 44px;
  background-color: $alternative-purple-a;
  border-radius: 50%;

  &.errorModal {
    background-color: $alternative-red-a;
  }
}

.outerIconBackground {
  width: 56px;
  height: 56px;
  background-color: $alternative-purple-b;
  border-radius: 50%;

  &.errorModal {
    background-color: $alternative-red-b;
  }
}

$primary-purple: #8235FF;

.modalContent {
  padding: 0;
  border-radius: 8px;
  min-width: 520px;

  .modalCollectionSection {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;

    &.errorModal:before {
      background: $alternative-red-c;
      border-bottom: 2px solid $alternative-red-b;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      width: 100%;
      height: 99%;
      background: $accent-purple;
      border-radius: 70%;
      transform-origin: bottom;
      transform: translateX(-50%) scale(2);
      border-bottom: 2px solid $alternative-purple-b;
    }

    .modalCollectionContent {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      max-width: 85%;
      text-align: center;
      font-size: 16px;
      line-height: 28px;
      padding-bottom: 10px;

      .modalTitleText {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .modalHeader {
      border-bottom: none;
      justify-content: center;
      padding-bottom: 10px;
    }
  }

  .modalFooter {
    border-top: none;

    div {
      width: 100%;
      justify-content: center;
    }

    .buttonWrapper {
      padding-top: 20px;
    }

    .btnSecondary {
      margin-right: 7px;
    }

    .btnPrimary {
      margin-left: 7px;
    }
  }
}

.modal-dialog {
  top: 5em;
}

.bottom-bar-input {

  &:after {
    content: '';
    position: absolute;
    top: 47px;
    width: 13.1em;
    left: 66px;
    right: 5px;
    border-bottom: 1px solid #23282c;
    display: block;
  }
}

input, textarea {
  &:focus {
    outline: none
  }
}

.card-title {
  font-weight: 600 !important;
}

.textButtonUnderline {
  position: relative;

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: -2%;
    width: 105%
  }

  &.cancelTextButtonUnderline {
    &:after {
      background-color: $black-grey;
    }

    &:hover:after {
      background-color: $teal;
    }
  }

  &.continueTextButtonUnderline {
    &:after {
      background-color: $teal;
    }

    &:hover:after {
      background-color: $black-grey;
    }
  }
}

.cancelTextButton {
  color: $black-grey;
  font-weight: 600;

  &:hover {
    color: $teal;
    text-decoration: none;
  }
}
