@import "../scss/variables";

.container {
  position: absolute;

  background: $white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  z-index: 10;
}

.text {
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 140.62%;
  display: flex;
  align-items: center;
}

.grayColor {
  color: $message-gray;
}

.blackColor {
  color: $black-grey
}

.deleteIcon {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
  display: flex;
  align-items: center;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.darkDisabled {
  background: $weak-grey;
  border: 0.5px solid $message-gray;
  color: $message-gray;

  &:hover {
    background: $weak-grey;
    border: 0.5px solid $message-gray;
    color: $message-gray;
  }
}
