@import "../../../scss/variables";

.spinner {
  font-weight: 400;
  font-size: 2.5rem;
  color: $standard-grey;
}

.title {
  font-weight: 400;
  font-size: 1.5rem;
  color: $standard-grey;
}

.infoText {
  font-weight: 400;
  font-size: 0.875rem;
  color: $idle-gray;
}
