@import '../scss/variables';

.button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  position: relative;
  border-radius: 10px;
  border: none;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;

  i, img {
    margin-right: 0.375rem;
  }
}

.anchor {
  display: inline-flex;
}


.primary {
  background: $purple;
  color: $white;
  transition: all 0.25s ease-out;

  &:hover {
    background: $compliment-purple;
  }

  &:active, &:focus {
    background: $alternative-purple-a;
    outline: none;
  }
}

.secondary {
  background: $white;
  color: $standard-grey;
  outline: $weak-grey 0.5px solid;
  transition: all 0.25s ease-out;

  &:hover {
    background: $faded-grey;
    outline: 0.5px solid $weak-grey;
  }

  &:active, &:focus {
    background: $weak-grey;
    outline: 0.5px solid $weak-grey;
  }
}

.ternary {
  background: $weak-grey;
  color: $standard-grey;
  box-shadow: none;
  outline: 0.5px solid $message-gray;
  transition: all 0.25s ease-out;

  &:hover {
    background: $message-gray;
    box-shadow: none;
    outline: 0.5px solid $idle-gray;
  }

  &:active, &:focus {
    background: $active-grey;
    color: $white;
    box-shadow: none;
    outline: none;

    i {
      color: $white;
    }
  }
}

.transparent {
  color: $standard-grey;
  background: none;
  outline: 0.5px solid transparent;
  transition: all 0.25s ease-out;

  &:hover {
    background: $faded-grey;
    outline: 0.5px solid $weak-grey;
  }

  &:active, &:focus {
    background: $weak-grey;
    outline: 0.5px solid $weak-grey;
  }
}

.transparentSecondary {
  color: white;
  background-color: transparent;
  box-shadow: none;
  transition: all 0.25s ease-out;

  &:hover {
    background-color: $alternative-purple-a;
  }

  &:active, &:focus {
    color: $standard-grey;
    background-color: $accent-purple;
    outline: none;
  }
}

.danger {
  background: $danger;
  color: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
  transition: all 0.25s ease-out;

  &:hover {
    background: $standard-grey;
    box-shadow: none;
  }

  &:active, &:focus {
    background: $active-grey;
    box-shadow: none;
  }
}

.disabled {
  color: $weak-grey;
  background: $accent-gray;
  outline: 0.5px solid $weak-grey;
  box-shadow: none;

  &:hover, &:active, &:focus {
    background: $accent-gray;
    outline: 0.5px solid $weak-grey;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.removeIcon {
  background-color: $danger;
  color: $white;
  font-size: 0.625rem;
  padding: 0.25rem;
  position: absolute;
  top: -0.5625rem;
  right: -0.5625rem;
  border-radius: 50%;
}
