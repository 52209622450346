@import "../../../scss/variables";

.variantText {
  font-size: 0.875rem;
  color: $dark-grey;
  margin-right: 5px;

  &Active {
    transition: color 0.25s ease-out;
    color: $white;
  }
}

.variantTagColor {
  width: 1.375rem;
  height: 1.375rem;
  margin-left: -5px;
  border-radius: 50%;
  border: 1px solid $message-gray;
  outline: 0.125rem solid $white;
  transition: outline-color 0.25s ease-out;

  &MissingData {
    outline-color: $alternate-yellow-c;
  }

  &Hovered {
    outline-color: $faded-grey;
  }

  &Active {
    outline-color: $weak-grey;
  }
}

.variantOptionColor {
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  border: 1px solid $message-gray;
}

.orderProductRow {
  background: $white;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:hover {
    background: $light-grey;
  }
}

.orderProductRowText {
  font-size: 16px;
  color: $black-grey;
}

.productImagePlaceholder {
  height: 3.25rem;
  width: 3.25rem;
  background-color: white;
  border-radius: 8px;
  border: 0.5px solid $weak-grey;

  &Icon {
    font-size: 1.5rem;
    font-weight: 900;
  }
}

.productRowTopContainer {
  border-bottom: 0.5px solid $disabled-gray;
}

.rowFooterText {
  font-weight: 600;
  font-size: 14px;
  color: $black-grey;
  line-height: 20px;
}

.productImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  border: 0.5px solid $weak-grey;

  & > img {
    height: 100%;
    margin: 0 auto;
  }
}

.missingValuesText {
  cursor: default;
  margin-bottom: 0;
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: $standard-grey;
}

.missingValuesIcon {
  font-weight: 900;
  font-size: 0.75rem;
  color: $warning-yellow;
}

.missingValuesDropdownText {
  margin: 0;
  white-space: pre-wrap;
  text-align: center;
  max-width: 12rem;
}
