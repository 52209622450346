@import '../scss/variables';

.orderConfirmationButtonPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 240px;
  height: 36px;
}

.headerPlaceholder {
  padding: 20px 24px;
  border-bottom: 0.5px solid $disabled-gray;
}

.collectionDropdownPlaceholder {
  background-color: $disabled-gray;
  width: 161px;
  height: 32px;
  margin-bottom: 5px;
  border-radius: 40px;
}

.searchBarPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 400px;
  height: 32px;
}

.paginationButtonPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

.paginationPlaceholder {
  width: 30px;
  height: 32px;
}

.distributeProductsPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 222px;
  height: 32px;
}

.roundedTableHeaderPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 1486px;
  height: 30px;
  display: block;
}

.productRowPlaceholder {
  background-color: $disabled-gray;
  border-radius: 8px;
  width: 1486px;
  height: 92px;
  display: block;
}
