@import "../../../scss/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 7rem 3rem 7rem
}

.toggleButton {
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  padding: 0.5rem;
  font-size: 1.25rem;
  color: $idle-gray;
  cursor: pointer;
}

.title {
  margin-block: 1rem 0;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $standard-grey;
}

.subtitle {
  margin-block: 0.5rem 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $idle-gray;
}

.downloadButton {
  margin-top: 1rem;
}
