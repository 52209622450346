@import "../../../scss/variables";

.container {
  width: 45.5rem;
  padding: 1.5rem 3rem 2rem 3rem;
}

.title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $standard-grey;
  margin-right: 0.375rem;
}

.infoIcon {
  font-weight: 100;
  font-size: 1rem;
  vertical-align: middle;
}

.subtext {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $idle-gray;
}

.textarea {
  height: 21rem;
}
