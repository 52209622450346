@import '../../../../../scss/variables';

.brandRuleIcon {
  background-color: $primary-gray;
  color: white;

  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  font-size: 0.75rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 0.625rem;
}

.horizontalLine {
  margin-left: -3rem;
  margin-right: -1.5rem;
}
