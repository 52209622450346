@import "../../../../../scss/variables";

.hintText {
  color: $idle-gray;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  margin: 0;
}

.whiteSheetIcon {
  fill: white;
}

.darkSheetIcon {
  fill: $standard-grey;
}
