@import "../../../scss/variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 3rem 1.5rem 3rem;
  width: 34rem;
  background-color: $accent-gray;
  border-radius: 1rem;
}

.closeIconContainer {
  width: min-content;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  color: $idle-gray;
  font-size: 1.25rem;
}

.title {
  margin-block: 1rem 0;
  font-size: 1.5rem;
  color: $standard-grey;
}

.infoText {
  margin-bottom: 0;
  font-size: 0.875rem;
  color: $idle-gray;
  text-align: center;
}

.copyBarcodesText {
  color: $purple;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.188rem;
    cursor: pointer;
  }
}

.separator {
  width: 100%;
  margin-block: 1.5rem;
  border-top: 0.5px solid $weak-grey;
}

.missingBarcodesSmallContainer {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.missingBarcodesContainer {
  margin-top: 0.75rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.barcodeText {
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: center;
  width: min-content;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.875rem;
  color: $standard-grey;
}

.showAllButton {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $purple;
  font-size: 0.875rem;
  cursor: pointer;
}
