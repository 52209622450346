@import '../../../../../../scss/variables';

.dropdownToggle {
  height: 2rem;

  :focus {
    background-color: $black !important;
    color: white !important;
  }
}

.iconBackground {
  width: 24px;
  height: 24px;
  background-color: $black-grey;
}
