@import "../scss/variables";

.paginationText {
  min-width: 2.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 0.625rem;
  text-align: center;
}

.noContent {
  color: $disabled-gray;
}

.navContent {
  color: $standard-grey;
}
