@import '../scss/variables';

.midButton {
  height: 32px;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  &.midSecondary {
    color: $black-grey;
    background-color: white;
  }

  &.midPrimary {
    color: white;
    background-color: $teal;
  }

  &:hover {
    background-color: $black-grey;
    color: white;
  }

  &:active {
    background-color: #333333;
    color: white;
  }

  &:focus {
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background-color: $disabled-gray;
    color: white;
  }
}
