@import '../../../scss/variables';

.dropdownItem {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.063rem;
  color: $standard-grey;
}

.radioBtn {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.5rem !important;
}

.selectedRadioBtn {
  color: $purple;
}
