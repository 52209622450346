@import "../../../scss/variables";

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 400;
  color: $standard-grey;
  margin-right: 0.375rem;
  margin-bottom: 0;
  line-height: 2rem;
}

.subtitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  color: $black-grey;
  margin-bottom: 0;
}

.separator {
  height: 0.625rem !important;
  margin-inline: 0.5rem !important;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.deleteButton, .paginationButton{
  height: 2rem;
  width: 2rem;
}

.tabContent {
  display: flex;
  font-weight: 400;
}

.boldText {
  margin: 0;
  font-weight: 600;
}
