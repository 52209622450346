@import "../../scss/variables";

.orderRow {
  display: flex;
  border-bottom: 0.5px solid $disabled-gray;

  &LastItem {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.purpleBackground {
  background-color: $faded-purple;
}

.yellowBackground {
  background-color: $alternate-yellow-c;
}

.orderCell {
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $black-grey;

  .orderNumber {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .orderCompanyName {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.tagsContainer {
  min-width: 4.75rem;
  margin-right: 0.5rem;
}

.tagContainer {
  margin-bottom: -0.25rem;
}

.tagText {
  margin: 0;
  font-weight: 400;
  font-size: 0.625rem;
  color: $standard-grey
}

.orderText {
  width: 100%;
  margin: 0;
}

.rightCaretIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  color: $idle-gray;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding: 0.75rem;
  margin-left: 0.25rem;

  &:hover {
    color: $standard-grey;
    background-color: $accent-purple;
    border-radius: 50%;
  }
}

.checkbox {
  line-height: 120%;
}

.dataStatusContainer {
  margin-left: 0.75rem;
}

.dataIcon {
  font-weight: 900;
  font-size: 0.75rem;
}

.icon {
  &Green {
    color: $good-green;
  }

  &Yellow {
    color: $warning-yellow;
  }

  &Grey {
    color: $message-gray;
  }

  &IdleGrey {
    color: $idle-gray;
  }
}

.downloadDateContainer {
  min-width: 12.5rem;
}

.dataText {
  margin-bottom: 0;
  margin-left: 0.375rem;
  color: $standard-grey;
  font-size: 0.75rem;
}

.downloadButtonContainer {
  min-width: 7.125rem;
  display: flex;
  justify-content: end;
}

.errorsIconContainer {
  min-width: 1.375rem;
}

.missingDataIndicatorContainer {
  min-width: 12rem;
  margin-right: 1.25rem;

  &WithoutTags {
    margin-right: 4rem;
  }
}

.commentInput {
  margin-left: -0.75rem;
  font-weight: 400;
}

.commentTooltipWrapper {
  max-width: 100%;
  margin-left: -0.75rem;
}

.commentButton {
  max-width: 100%;
  background: transparent;
  outline: none;
}

.commentText {
  margin: 0;
  color: $standard-grey;
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.commentIcon {
  font-size: 0.75rem;
}
