@import '../../scss/variables';

.selectedOrdersCountContainer {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.deselectAllIcon {
  font-weight: 300;
  color: $idle-gray;
  cursor: pointer;
  transition: color ease-out 0.2s;

  &:hover {
    color: $purple;
  }
}

.noSearchResultsContainer {
  margin-top: 8rem;
}

.noContentContainer {
  border-top: 0.5px $weak-grey solid;
  padding-top: 8rem;
}

.missingDataStatusHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 34.063rem;

  &WithoutTags {
    @extend .missingDataStatusHeaderContainer;
    min-width: 31.5rem;
  }
}

.statusDropdown {
  margin-left: -0.75rem;
}
