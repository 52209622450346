@import "../../../scss/variables";


.modal {
  min-width: 730px;
}

.modalContent {
  padding: 0 !important;
  border-radius: 8px !important;
}

.productInfoHeader {
  padding: 32px 32px 0 32px;

  h5 {
    color: $primary-gray;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  p {
    color: $primary-gray;
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
  }
}


.navigationNav {
  border-bottom: 2px solid $message-gray;
  padding: 0 16px;

  .navLink {
    border: 0;
    color: $black;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;

    &:hover {
      border-bottom: 3px solid $alternative-purple-b;
    }

  }

  .navLinkActive {
    border-bottom: 3px solid $primary;
    font-weight: 600;

    &:hover {
      border-bottom: 3px solid $primary;
    }
  }
}

.tabPane {
  padding: 0;
}

.tabContent {
  border: 0;
  height: 500px;
  overflow-y: auto;
}


.imageCarouselContainer {
  height: 160px;
  width: 160px;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }

  .imgNavBtn {
    box-shadow: none;
    border: 0;
    background-color: transparent;

    &:disabled {
      background-color: transparent !important;
      color: $message-gray !important;
    }

    &:hover:enabled {
      background-color: transparent !important;
      color: $dark-grey;
    }

    &:focus {
      background-color: transparent;
    }
  }
}
