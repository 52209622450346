@import "../../../../scss/variables";

.chevron {
  font-weight: 900;
  font-size: 0.75rem;
  color: $white;
}

.pageInfo {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.container {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  background-color: $black-grey;
  padding: 0.125rem 0.75rem;
  height: 2.75rem;

}

.whiteText {
  color: $white;
}

.yellowText {
  color: $warning-yellow;
}

.mediumMarginRight {
  margin-right: 0.75rem;
}

.headerText {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  white-space: nowrap;
}

.indexCircle {
  min-width: 1.5rem;
  height: 1.5rem;
  background-color: $white;
  border: 0.5px solid $black-grey;
  border-radius: 1.5rem;
  font-weight: 400;
  font-size: 0.75rem;
}


.exampleText {
  color: $warning-yellow;
  margin-left: auto;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: block
}

.previewDropdownToggle {
  background: transparent;
  cursor: pointer;

  &:hover {
    color: $disabled-gray;
  }
}


.expandedContainer {
  overflow: visible;

  .expandedContainerCard {
    position: absolute;
    top: 0.5625rem;
    z-index: 99999;
    width: 26rem;
    right: 0;
    box-shadow: 0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.3);

    :global .badge.badge-primary {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0.125rem 0.75rem;
      border-radius: 1rem;
      cursor: pointer;
      margin-left: 0.5rem;

      i {
        margin-left: 0.25rem;
      }

      &:hover {
        background-color: $primary-gray;
      }
    }
  }
}


.exampleProductRow {
  padding: 0.375rem 0.75rem;
  margin: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    background-color: $accent-purple-2;
  }
}

.inputGroup {
  border: 1px solid $idle-gray;
  border-radius: 0.5rem;

  .inputGroupTextArea {
    border-style: none;
    border-color: $idle-gray;

    &:focus {
      box-shadow: none;
      border-color: $black-grey;
    }
  }

  &:focus-within {
    border: 1px solid $black-grey;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  }
}
