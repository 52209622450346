@import "../../../../../scss/variables";



.sheetRow {
  background-color: $accent-gray;
  color: $standard-grey;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  vertical-align: center;

  padding: 1.5rem 3rem;

  &.lastSheetRow {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}



.divider {
  height: 1px;
  margin: -1px 4rem 0;
  background-color: $weak-grey;
}
