@import "../../../scss/variables";

.overtitle {
  color: $standard-grey;
  font-size: 0.75rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1rem;
}

.header {
  font-size: 1.75em;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.chevron {
  margin-right: 0.375rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
}
