@import '../../../scss/variables';

.container {
  min-width: 100%;
  width: 45.5rem;
  padding: 1.5rem 3rem;
}

.textContainer {
  width: 5.625rem;
  margin-right: 0.75rem;
  margin-block: auto;
}

.text {
  color: $standard-grey;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: end;
  margin: 0;
}

.orderNumberInput {
  border: 0.5px solid $message-gray;
  font-size: 0.75rem;
}

.infoIcon {
  color: $idle-gray;
  margin-left: 0.625rem;
}
