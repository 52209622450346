@import '../scss/variables';

.buttonPlaceHolder {
  background-color: $disabled-gray;
  display: block;
  border-radius: 8px;
  height: 32px;
}

.rowPlaceHolder {
  background-color: $disabled-gray;
  display: block;
  border-radius: 8px;
  height: 64px;
}
