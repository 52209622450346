@import '../../../../scss/variables';

.container {
  background: $light-grey;
  margin: 2rem 2.25rem;

  /* Weak */
  border: 0.5px solid $disabled-gray;
  border-radius: 20px;
}

.innerContainer {
  background: $white;
  width: 100%;
  border: 0.5px solid $message-gray;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.firstRow {
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.innerRow {
  margin-top: 1rem;
  margin-left: 3.625rem;
  margin-right: 3.625rem;
}

.label {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: $black-grey;
}

.paragraph {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: $black-grey;
}
