@import '../../../../../scss/variables';

.container {
  padding: 1.5rem;
  width: 100%;
  background-color: $light-grey;
  border-radius: 1.25rem;
  border: 0.5px solid $weak-grey;
  color: $primary-gray;
  font-size: 16px;
  line-height: 22.5px;
  margin-bottom: 1rem;

  &:first-of-type {
    margin-top: 1rem;
  }
}


.actionDropdown {
  img {
    margin-right: 0.5rem;
  }
}

.removeIcon {
  color: $idle-gray;
  cursor: pointer;
  font-size: 0.875rem;
}


.actionRow {
  font-weight: 400;
  line-height: 24px;
  padding-left: 1rem;

  img {
    height: 12px;
    margin-bottom: 4px;
  }

}

.charactersLimitInput {
  min-width: 2rem;
  max-width: 3rem;
  margin: 0 12px;
  border-radius: 8px !important;
  text-align: center;
}

.iconBackground {
  width: 24px;
  height: 24px;
  background-color: $black-grey;
}

.dropdownToken {
  display: flex;
  justify-content: center;
  align-content: center;

  height: 2rem;
  background: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;

  &:focus {
    background-color: $black !important;
    color: white !important;
  }
}

.dropdownToggle {
  width: 2rem;
  height: 2rem;

  :focus {
    background-color: $black !important;
    color: white !important;
  }
}

.fromDisplayToken button {
  background-color: $accent-purple-2;
  color: $purple;
}
