@import "../../../scss/variables";

.row {
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 92px;
  transition: all 0.25s ease-out;
  cursor: pointer;

  &:hover {
    background-color: $accent-purple-2;
  }

  td {
    vertical-align: middle;
    font-size: 16px;
    color: $primary-gray;
    line-height: 28px;
  }

  &.withVariantSelection {
    border-radius: 8px 8px 0 0;
    clip-path: inset(-5px -5px 0 -5px);
  }

  &.withVariantSelectionExpanded {
    border-radius: 8px 8px 0 0;
    clip-path: inset(-5px -5px 0 -5px);
    background-color: $accent-gray;
  }
}


.checkboxCell {
  width: 5%;
}

.productImageCell {
  width: 5%;
}

.productName {
  font-weight: 400;
}


.productImage {
  height: 64px;
  width: 64px;
  border-radius: 8px;
}


.variantSelection {
  box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 8px 8px;
  color: $primary-gray;
  font-size: 14px;
  height: 64px;

  td {
    padding: 16px;
  }


  .selectedCount {
    font-weight: 600;
    line-height: 38px;
  }

  .selectedList {
    font-weight: normal;
    line-height: 38px;
  }


  button {
    float: right;
  }

}

.variantSelectionExpanded {
  box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 8px 8px;
  background-color: $accent-gray;
  color: $primary-gray;
  font-size: 14px;
  border-top: 1px solid $message-gray;

  td {
    padding: 16px;
  }

  .selectedCount {
    font-weight: 600;
    line-height: 26px;
  }

  .expandedColorName {
    font-weight: 600;
    color: $primary-gray;
    font-size: 14px;
    line-height: 20px;
    padding-right: 5px;
  }

  .expandedColorCode {
    font-weight: 400;
    color: $idle-gray;
    padding-left: 5px;
  }
}

.spacer {
  height: 20px;
}
