@import "../../../../../../../scss/variables";

.iconContainer {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: $standard-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;;
}

.icon {
  color: white;
  font-size: 0.625rem;
}

.lightText {
  color: $standard-grey;
}

