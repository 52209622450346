$primary-purple: #8235FF;

.modal {
  font-family: Lato, sans-serif;
  color: $primary-gray;

  .modal-content {
    padding: 0;
    border-radius: 8px;
    min-width: 520px;

    &.ddt-config-modal {
      padding: 0.75rem;
    }

    .modal-collection-section {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;

      &:before {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 99%;
        background: $accent-purple;
        border-radius: 70%;
        transform-origin: bottom;
        transform: translateX(-50%) scale(2);
        border-bottom: 2px solid $alternative-purple-b;
      }

      &.error-modal:before {
        background: $alternative-red-c;
        border-bottom: 2px solid $alternative-red-b;
      }


      .modal-collection-content {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        max-width: 85%;
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        padding-bottom: 10px;

        .modal-input {
          padding-top: 0;
          min-width: 290px;
          border: none;
          background-color: $accent-purple;
          color: $dark;
          font-size: 24px;
          text-align: center;
          border-bottom: 1px solid $black-grey;
          margin-bottom: 10px;
        }

        .modal-img {
          margin-top: 16px;
        }

        .modal-title-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }

      .modal-header {
        border-bottom: none;
        justify-content: center;
        padding-bottom: 10px;
      }
    }

    .modal-footer {
      border-top: none;

      div {
        width: 100%;
        justify-content: center;
      }

      .warning-link {
        color: $primary-purple;
        font-weight: 600;
        text-decoration-line: underline;

        & .error-icon-purple {
          width: 28px;
          margin-right: 10px;
        }
      }

      .button-wrapper {
        padding-top: 20px;
      }

      .btn-secondary {
        margin-right: 7px;
      }

      .btn-primary {
        margin-left: 7px;
      }
    }
  }
}

.modal-dialog {
  top: 5em;
}

input, textarea {
  &:focus {
    outline: none
  }
}


