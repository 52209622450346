@import '../../../scss/variables';

.headerStripePlaceholder {
  background-color: $disabled-gray;
  height: 40px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
}

.buttonPlaceholder {
  background: $disabled-gray;
  border-radius: 8px;
  height: 32px;
}

.rowPlaceholder {
  background-color: $disabled-gray;
  display: block;
  border-radius: 8px;
  height: 149px;
}
