@import "../../../scss/variables";

.container {
  min-width: 34rem;
  padding: 4.375rem 5rem 3rem 5rem;
}

.title {
  color: $standard-grey;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.subtitle {
  color: $idle-gray;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.buttonText {
  color: $standard-grey;
  font-size: 0.75rem;
  margin: 0;
}

.toggleIcon {
  position: absolute;
  top: 1rem;
  right: 1.625rem;
  cursor: pointer;
}
