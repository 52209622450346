@import '../../../../scss/variables';

.suffixColumns {
  max-width: min-content;
  padding: 0;
}

.container {
  background: $light-grey;
  border: 0.5px solid $disabled-gray;
  border-radius: 0.5rem;
  width: 11.375rem;
  padding: 0;
  height: 3rem;
  margin-bottom: 1rem;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}

.numericToken {
  height: 2rem;
  width: 7.25rem;
  background: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.75rem;

  &:focus {
    background: $black-grey;
    color: $white
  }
}

.suffixController {
  width: 16.5625rem;
  height: 9.75rem;
  position: absolute;
  background: $white;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;

  z-index: 9;
}

.horizontalLine {
  border: 0.5px solid $weak-grey;
  width: 100%;
  margin: 0;
}

.dropDownMenu {
  height: inherit;
  padding: 0;
}

.containerText {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: $black-grey;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.input {
  border: 0.5px solid $idle-gray;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: 0.5rem;
  text-justify: auto;
  padding-right: 0.5rem;
  text-align: center;
}

.input:focus {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid $alternative-purple-a;
}

.grayText {
  color: $idle-gray
}
