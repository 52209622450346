@import '../../../scss/variables';

.container {
  min-width: 100%;
  width: 34rem;
  padding: 1.5rem 3rem;
}

.text {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $black-grey;
}

.includeImagesCheckboxRow {
  margin: 0.813rem 0 0 0;
}

.redirectCardText {
  margin-bottom: 0;
  margin-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: $black-grey;
}

.starIcon {
  color: $warning-yellow;
}

.arrowIcon {
  font-weight: 400;
  font-size: 0.75rem;
  color: $black-grey;
}

.redirectCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 12px;
  border: 0.5px solid $weak-grey;
  background-color: white;
  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    background: $weak-grey;
    outline: 0.5px solid $idle-gray;
  }
}

.lightDropdownText {
  margin-left: auto;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: $idle-gray;
}

.separator {
  margin: 1.5rem 0;
  border-color: $weak-grey;
  border-width: 0.5px;
}
