.downloadButton {
  height: 2rem;
  font-weight: 400;
  font-size: 0.75rem;
}

.modal {
  min-width: 35rem;
  overflow: visible;
}
