@import 'src/ui-kit/src/scss/variables';

.orderConfirmationHeader {
  background: $accent-purple;
  border-bottom: 0.5px solid $message-gray;
}

.cardTitle {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h4 {
    line-height: 38px;
    vertical-align: middle;
  }
}

.withOrderConfirmation {

  .uploadsWrapper {
    margin-right: auto;

    :global .badge {
      // default styling for the pill
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      font-size: 16px;
      line-height: 28px;
      padding: 0 16px;
      font-weight: 500;
      margin-left: 8px;
      cursor: pointer;
      height: 32px;

      transition: all 0.25s ease-out;

    }

    :global .upload {
      background-color: $white;
      color: $primary;

      &:hover {
        background-color: $accent-gray;
      }

    }

    :global .upload.active {
      background-color: $primary;
      color: $white;

      &:hover {
        background-color: $secondary-gray;
      }
    }
  }
}

.orderConfirmationErrors {
  :global .popover {
    min-width: 500px;
  }

  :global .popover-inner {
    max-height: 500px;
    overflow-y: auto;
  }

}
