@import '../../../../scss/variables';

.header:not(.saveDisabled) > span:hover {
  color: $purple-shade;
}

.titleContainer {
  padding: 32px 32px 24px 32px;
  margin-left: -12px;

  h5 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: $primary-gray;
  }
}

.body {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 2.25rem;
  margin-right: 1.25rem;


  .rules {
    margin-right: 1rem;
    row-gap: 24px;
    overflow-y: visible;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background-color: $light-grey;
    border: 0.5px solid $disabled-gray;
    border-radius: 1.25rem;

    .generalRulesText {
      margin-right: 12px;
      font-weight: 600;
    }
  }

  .brandRulesWrapper {
    background-color: $light-grey;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 0.5rem;
    color: $primary-gray;
    font-size: 16px;
    line-height: 22.5px;
    font-weight: 600;
    margin-top: 24px;
  }
}
