@import "../scss/variables";

.productImagePlaceholder {
  border-radius: 8px;
  background-color: $accent-gray;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);

  i {
    color: $secondary-gray;
  }
}

.sizeNormal {
  height: 64px;
  width: 64px;
}

.sizeLg {
  height: 160px;
  width: 160px;
}
