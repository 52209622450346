@import "../scss/variables";

.container {

  input {
    opacity: 0;
    height: 0.875rem;
    width: 0.875rem;
    position: absolute;

    &:checked ~ .label::before {
      border: 4px solid $primary;
    }

    &:hover ~ .label::before {
      box-shadow: 0 0 4px $alternative-purple-a;

    }
  }

  .label {
    margin-bottom: 0;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: $standard-grey;
    margin-left: 0.875rem;

    &:before {
      content: "";
      left: 0.5rem;
      display: block;
      position: absolute;
      height: 0.875rem;
      width: 0.875rem;
      border-radius: 50%;
      border: 1px solid $message-gray;
      pointer-events: none;
    }
  }

}
