@import '../../../scss/variables';

.container {
  width: 45.5rem;
  padding: 1.5rem 3rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 0.875rem;
  color: $standard-grey;
  margin: 0 0.375rem 0 0;
}

.infoIcon {
  font-weight: 100;
  font-size: 1rem;
  color: $idle-gray;
}

.subtitle {
  font-weight: 400;
  font-size: 0.875rem;
  color: $idle-gray;
  margin: 0.25rem 0;
}

.textarea {
  margin-top: 1.5rem;
  height: 21rem;
  resize: none;
}
