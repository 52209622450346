@import "../../../../scss/variables";

.container {
  margin-left: 32px;
  margin-right: 32px;
}

.row {
  width: 100%;
  padding: 24px 8px;
  margin: 0 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $message-gray;

  &:last-child {
    border-radius: 0;
    border-bottom: 0;
  }

}

.rowVertical {
  width: 100%;
  padding: 24px 8px;
  margin: 0 1px;
  display: flex;
  flex-direction: column;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $message-gray;

  &:last-child {
    border-radius: 0;
    border-bottom: 0;
  }

}

.rowKey {
  display: inline-block;
  font-weight: 600;
  color: $black;
  font-size: 16px;
  line-height: 22px;
}

.rowValue {
  display: inline-block;
  font-weight: normal;
  color: $black;
  font-size: 16px;
  line-height: 22px;
}


.sizingTableRow {
  padding: 0;
  color: $black;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
  height: 72px;
  border-bottom: 1px solid $message-gray;
  vertical-align: center;
}
