@import 'src/ui-kit/src/scss/variables';

.smallFont {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.dropdownActiveIcon {
  font-weight: 900 !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  color: $purple;

}

.dropdownInactiveIcon {
  font-weight: 100 !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  color: $message-gray;
}

.dropdownItemIcon {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem !important;
}

.dropdownOptionHover:hover {
  & i {
    transition: font-weight 0.25s ease-out;
    font-weight: 900 !important;
  }
}