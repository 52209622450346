@import "../scss/variables";


.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  width: 32px !important;
  border-radius: 8px 0 0 8px !important;
  border: 1px solid $primary-gray;
}

.input {
  border-radius: 0 8px 8px 0 !important;
}
